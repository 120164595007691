import {useStaticQuery, graphql} from 'gatsby';

export const GetBackground = () =>  {
	
	const content = useStaticQuery(graphql`
	query background {
		allMarkdownRemark(
		  filter: {fields: {collection: {eq: "Background"}}}
		) 
		{
		  	edges {
				node {
				frontmatter {
						home{
							childImageSharp {
								fixed {
								  src
								}
							  } 
						}
						foundation{
							childImageSharp {
								fixed {
								  src
								}
							  } 
						}
						actions{
							childImageSharp {
								fixed {
								  src
								}
							  } 
						}
						blog{
							childImageSharp {
								fixed {
								  src
								}
							  } 
						}
						donate{
							childImageSharp {
								fixed {
								  src
								}
							  } 
						}
						contact{
							childImageSharp {
								fixed {
								  src
								}
							  } 
						}
						events{
							childImageSharp {
								fixed {
								  src
								}
							} 
						}
						publications{
							childImageSharp {
								fixed {
								  src
								}
							} 
						}
						interface{
							childImageSharp {
								fixed {
								  src
								}
							} 
						}
					}
				}
		  	}
		}
	}
`);

	return content.allMarkdownRemark.edges[0].node.frontmatter;
};