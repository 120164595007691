import { Link } from 'gatsby';
import React, { useState } from 'react';
import Logo from './../../images/logo.svg'
import './nav.scss';
import {a, useSpring} from 'react-spring'

const Nav = ({menuItems}) => {
    const [hover, setHover] = useState([false, -1])
    const [open, setOpen] = useState(false);


	const topBun = useSpring({
		from: { transform: 'scale(1)', top: '0px', opacity: 1 },	
		to: {
			transform: open ? 'scale(0)' : 'scale(1)',
			top: open ? '7px' : '0px',
			opacity: open ? 0 : 1,
		},
	});
	const salade = useSpring({
		to: { transform: open ? 'rotate(45deg)' : 'rotate(0deg)', top: '4px' },
		from: { transform: 'rotate(0deg)' },
	});
	const steak = useSpring({
		to: { transform: open ? 'rotate(-45deg)' : 'rotate(0deg)', top: '-4px' },
		from: { transform: 'rotate(0deg)' },
	});
	const bottomBun = useSpring({
		from: { transform: 'scale(1)', top: '0px', opacity: 1 },
		to: {
			transform: open ? 'scale(0)' : 'scale(1)',
			top: open ? '-6px' : '0px',
			opacity: open ? 0 : 1,
		},
	});

    return (
        <>
        	<button className="mobile-toggler nav-burger-button" aria-label={open ? 'Fermer le menu' : 'Ouvrir le menu'} onClick={() => setOpen(!open)}>
				<a.span 
					className="burger-slice"
					aria-hidden="true" 
					style={topBun}>
				</a.span>
				<a.span
					className="burger-slice"
					aria-hidden="true"
					style={salade}
					top={'4px'}
				></a.span>
				<a.span
					className="burger-slice"
					aria-hidden="true"
					style={steak}
					top={'-4px'}
				></a.span>
				<a.span					
					className="burger-slice"
					aria-hidden="true"
					style={bottomBun}>
				</a.span>
			</button>

            <nav id="mobile" style={{
                transform: `translateY(${open ? 0 : '-100vh'})`
            }}>
                <Link to="/"><Logo /></Link>
                <ul className="mainmenu">
                {menuItems.map((e,i)=> (
                        <li key={i}>
                            <Link activeStyle={{color: '#fff'}} to={e.slug}>
                                {e.name}
                            </Link>
                            { 
                                    e.submenu &&
                                    <ul className="submenu">
                                    {e.submenu.map((e,i)=>(
                                        <li><Link activeStyle={{color: '#fff'}} to={e.slug}>{e.name}</Link></li>
                                    ))}
                                    </ul> 
                            }
                        </li>
                    ))}
                </ul>
            </nav>
            <nav id="desktop">
                <Link to="/"><Logo /></Link>
                <ul className="mainmenu">
                {menuItems.map((e,i)=> (
                        <li onMouseEnter={() => setHover([true, i])} onMouseLeave={() => setHover([false, -1])} key={i}>
                            <Link activeStyle={{color: '#fff'}} to={e.slug}>
                                {e.name}
                            </Link>
                            { 
                                    e.submenu &&
                                    <ul onMouseEnter={() => setHover([true, i])} onMouseLeave={() => setHover([false, -1])} className="submenu" style={{display: hover[0] && hover[1] === i ? 'inline-block' : 'none',}}>
                                    {e.submenu.map((e,i)=>(
                                        <li><Link activeStyle={{color: '#fff'}} to={e.slug}>{e.name}</Link></li>
                                    ))}
                                    </ul> 
                            }
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    );
};

export default Nav;