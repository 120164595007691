import React from 'react';

const MailTo = (props) => {

	let adress=[
        '\x68\x72\x65\x66',
        '\x6C\x6F\x63\x61\x74\x69\x6F\x6E',
        '\x6D\x61\x69\x6C\x74\x6F\x3A\x63\x6f\x6e\x74\x61\x63\x74\x40\x64\x65\x2d\x66\x6f\x75\x6e\x64\x61\x74\x69\x6f\x6e\x2e\x6f\x72\x67'
    ];

	function emailme(subject){window[adress[1]][adress[0]]= adress[2] + '?subject=' + subject;}

	return (
		<a onClick={() => emailme(props.subject)} className="Email" href="#">
			{props.children}
		</a>
	);
};

export default MailTo;