import React from 'react';
import MailTo from '../utils/mailTo';

const Footer = ({content}) => {
    
    return (
        <footer>
            <div className="footer-content">
                <div className="col">
                    <h6>{content.footerTitle}</h6>
                    {content.adresses.map((e,i) => (
                        <p>{e}</p>
                    ))}
                    <MailTo subject="Contact - de-foundation.org">
                        {content.email}
                    </MailTo>
                </div>
                <div className="col">
                    <h6>{content.footerSocialTitle} </h6>
                    {content.footerSocialLink.map((e,i)=> (
                        <a href={e.link}>{e.name}</a>
                    ))}
                </div>
                {/*
                <div className="footer-newsletter">
                    <form>
                        <h6>NEWSLETTER</h6>
                        <input type="email" placeholder="Email" />
                        <button>➔</button>
                    </form>
                </div>
                */}
            </div>
            <img alt="footer texture image" src="/b20aec57875f4296846e56393a3a39cf.webp" />
            <p className="copyright" >{content.copyright}</p>
        </footer>
    );
};

export default Footer;  