import React from 'react';
import { GetBackground } from '../../content/background/getBackground';
import Img from "gatsby-image"


const Background = ({page}) => {
    const path = GetBackground()

    return (
        <div>
            {path[page] && 
                <Img style={{
                    width: '100%',
                    height: '100vh',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: -1
                }}
                className="background" fixed={path[`${page}`].childImageSharp.fixed}/>}
        </div>

    );
};

export default Background;