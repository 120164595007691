import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
 
const Seo = ({ description, lang, meta, title, keywords }) => {
	const { site } = useStaticQuery(
		graphql`
       		query {
         		site {
           			siteMetadata {
						title
						description
           			}
         		}
       		}
     	`
	);
 
	const metaDescription = description || site.siteMetadata.description;
	const defaultTitle = site.siteMetadata?.title;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			meta={[
				{
					name: 'description',
					content: metaDescription,
				},
			].concat(meta)}
		/>
	);
};
 
Seo.defaultProps = {
	lang: 'fr',
	meta: [],
	description: '',
	keywords: '',
};
 
Seo.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};
 
export default Seo;