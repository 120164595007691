/** ****************************************************************************
		███╗   ██╗███████╗██████╗  █████╗  ██████╗
		████╗  ██║██╔════╝██╔══██╗██╔══██╗██╔═══██╗
		██╔██╗ ██║█████╗  ██║  ██║███████║██║   ██║
		██║╚██╗██║██╔══╝  ██║  ██║██╔══██║██║   ██║
		██║ ╚████║███████╗██████╔╝██║  ██║╚██████╔╝
		╚═╝  ╚═══╝╚══════╝╚═════╝ ╚═╝  ╚═╝ ╚═════╝

@description template des pages.

@author Victor Audetat <audetat.v@gmail.com>

@copyright © 2020  NEDAO Association (info@nedao.ch)
@see {@link https://nedao.ch}

@license
	This program is free software: you can redistribute it and/or modify
	it under the terms of the GNU Affero General Public License as published
	by the Free Software Foundation, either version 3 of the License, or
	(at your option) any later version.

	This program is distributed in the hope that it will be useful,
	but WITHOUT ANY WARRANTY; without even the implied warranty of
	MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
	GNU Affero General Public License for more details.

	You should have received a copy of the GNU Affero General Public License
	along with this program.  If not, see <https://www.gnu.org/licenses/>.
*******************************************************************************/

import React, {useContext} from 'react';
import './../../styles/responsive.scss';
import Seo from '../seo/seo';
import './../../styles/normalize.scss';
import './layout.scss';
import './../../styles/main.scss';
import {LangContext} from '../context/langContext';
import Nav from './Nav';
import Footer from './Footer';
import { GetLayoutFR } from '../../content/layout/fr/GetLayoutFR';
import { GetLayoutEN } from '../../content/layout/en/GetLayout';
import Background from './background';

const Layout = ({ title, children, description, lang, page}) => {
	const ctx = useContext(LangContext)
	const content = ctx.lang === 'fr' ? GetLayoutFR() : GetLayoutEN()
	return (
		<LangContext.Consumer>
		{langContext => ( 		
			<div className="global-wrapper">
				<Seo
					title={title}
					description={description}
					lang={lang}
				/>
				<Background
					page={page}
				/>
				<header>
					<Nav 
						menuItems={content.menuItems}
					/>
					<div className="language">
						<button style={{
							color: ctx.lang === 'en' ? 'white' : '#00B7DB'
						}} 
						onClick={() => langContext.setLang('en')}>EN</button>
						<button style={{
							color: ctx.lang === 'fr' ? 'white' : '#00B7DB'
						}} onClick={() => langContext.setLang('fr')}>FR</button>
					</div>
				</header>
				<main>
					{children}
				</main>
				<Footer 
					content={content}
				/>
			</div>
		)}
		</LangContext.Consumer>
	);
};

export default Layout;